<template>
  <div class="progress-container">
    <div class="d-center">
      <div
        class="p-1 progress-icon border-primary"
        :class="{
          'active-tracking': statusIndexCondition
            ? status >= statusIndexCondition && status != 10
            : condition,
        }"
      >
        <FeatherIcon size="30" :icon="icon" />
      </div>
    </div>
    <h6>{{ label }}</h6>
    <h5>{{ date }}</h5>
  </div>
</template>
<script>
export default {
  props: {
    date: String,
    label: String,
    icon: String,
    statusIndexCondition: Number,
    condition: Boolean,
    status: Number,
  },
};
</script>
