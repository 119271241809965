<template>
  <div id="tracker">
    <form class="d-md-flex justify-content-center" @submit.prevent="fetchOrder">
      <div class="d-flex justify-content-center mt-1 mt-md-0">
        <b-input-group class="d-flex mr-2">
          <b-form-input
            v-model="orderId"
            class="t-input"
            placeholder="# Order ID"
            type="number"
            min="0.00"
            required
          />
          <template #append>
            <b-dropdown :text="isMiniDc ? 'MiniDC' : 'B2B'" variant="primary">
              <b-dropdown-item @click="isMiniDc = false"> B2B </b-dropdown-item>
              <b-dropdown-item @click="isMiniDc = true">
                MiniDC
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-input-group>
      </div>
      <div class="d-flex justify-content-center mt-1 mt-md-0">
        <b-button
          v-if="isLoading"
          variant="primary"
          class="tracking-btn d-flex align-content-center"
          type="button"
          disabled
        >
          <b-spinner small />
          <span class="ml-1">Tracking...</span>
        </b-button>
        <b-button v-else variant="primary" class="tracking-btn" type="submit">
          Track Order
        </b-button>
      </div>
    </form>

    <b-modal
      v-model="modalShow"
      centered
      :size="order ? 'xl' : 'md'"
      ok-only
      style="min-width: 500px"
      :title="`Order #${orderId}`"
    >
      <div v-if="!order" class="text-center">
        <div class="d-center">
          <img src="@/assets/images/main/no-data.svg" width="200" />
        </div>
        <h3 class="mt-3">
          Order ID <span>#{{ orderId }}</span> does not exist
        </h3>
      </div>
      <div v-else>
        <TimelineProgress :order="order" :is-mini-dc="isMiniDc" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "@axios";
import TimelineProgress from "./tracker/TimelineProgress.vue";

export default {
  components: { TimelineProgress },
  data() {
    return {
      orderId: null,
      isLoading: false,
      order: null,
      modalShow: false,
      isMiniDc: true,
    };
  },
  methods: {
    async fetchOrder() {
      try {
        this.isLoading = true;
        let baseUrl = process.env.VUE_APP_API_TRACKER;
        if (this.isMiniDc) {
          baseUrl = `${process.env.VUE_APP_API_INTERNAL_URL}/api/v1`;
        }
        const response = await axios.get(
          `${baseUrl}/track-order/${this.orderId}`
        );
        if (response.status === 200) {
          this.order = response.data.data;
          if (
            (!this.isMiniDc &&
              (this.order.status === 17 || this.order.status === 12)) ||
            (this.isMiniDc &&
              (this.order.status === 9 ||
                this.order.status === 10 ||
                !this.order.status))
          ) {
            this.order = null;
          }
        } else {
          this.order = null;
        }
        this.isLoading = false;
        this.modalShow = true;
      } catch (error) {
        this.order = null;
        this.isLoading = false;
        this.modalShow = true;
      }
    },
  },
};
</script>
