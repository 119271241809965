<template>
  <div class="my-2 text-center" style="position: relative">
    <div v-if="isMiniDc" class="tracker-container">
      <CircleIcon
        label="Order Added"
        icon="ShoppingCartIcon"
        :condition="currentStep === 0 || (currentStep > 0 && currentStep < 9)"
        :date="order.date_added"
        :status="order.status"
      />
      <div class="line" />
      <CircleIcon
        label="Processing"
        icon="LoaderIcon"
        :condition="
          (currentStep > 0 && currentStep < 4) ||
          (currentStep > 3 && currentStep < 9)
        "
        :status="order.status"
      />
      <div class="line" />
      <CircleIcon
        label="Picked Up"
        icon="InboxIcon"
        :condition="currentStep === 4 || (currentStep > 4 && currentStep < 9)"
        :date="order.pickup_date"
        :status="order.status"
      />
      <div class="line" />
      <CircleIcon
        label="Out For Delivery"
        icon="TruckIcon"
        :condition="
          (currentStep >= 5 && currentStep <= 7) ||
          (currentStep > 7 && currentStep < 9)
        "
        :date="order.delivery_date"
        :status="order.status"
      />
      <div class="line" />
      <CircleIcon
        label="Delivered"
        icon="CheckCircleIcon"
        :condition="currentStep === 8"
        :date="order.received_at"
        :status="order.status"
      />
    </div>
    <div v-else class="tracker-container">
      <CircleIcon
        label="Order Added"
        icon="ShoppingCartIcon"
        :status-index-condition="1"
        :date="order.date_added"
        :status="order.status"
      />
      <div class="line" />
      <CircleIcon
        label="Processing"
        icon="LoaderIcon"
        :status-index-condition="2"
        :status="order.status"
      />
      <div class="line" />
      <CircleIcon
        label="Picked Up"
        icon="InboxIcon"
        :status-index-condition="6"
        :date="order.pickup_date"
        :status="order.status"
      />
      <div class="line" />
      <CircleIcon
        label="Out For Delivery"
        icon="TruckIcon"
        :status-index-condition="7"
        :date="order.delivery_date"
        :status="order.status"
      />
      <div class="line" />
      <CircleIcon
        label="Delivered"
        icon="CheckCircleIcon"
        :status-index-condition="8"
        :date="order.received_at"
        :status="order.status"
      />
    </div>
  </div>
</template>
<script>
import CircleIcon from "./CircleIcon";

export default {
  components: { CircleIcon },
  props: { order: Object, isMiniDc: Boolean },
  data() {
    return {
      currentStep: this.order.status,
    };
  },
};
</script>
