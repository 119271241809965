<template>
  <div id="home" class="main-body">
    <div class="video-container">
      <div class="video-background">
        <video autoplay loop :muted="isMute">
          <source
            src="https://minidc-live-spaces.sgp1.cdn.digitaloceanspaces.com/minidc/website-assets/Latest%2030%20Second%20Video.mov"
            type="video/mp4"
          />
        </video>
      </div>
      <a v-if="isMute" @click.prevent="isMute = false">
        <feather-icon icon="VolumeXIcon" class="mute-icon" size="50" />
      </a>
      <a v-else @click.prevent="isMute = true">
        <feather-icon icon="Volume2Icon" class="mute-icon" size="50" />
      </a>
    </div>
    <div id="smart-logistic" class="smart-logistic p-5 fadeIn">
      <h1 class="title fs-1">
        Walk into the new era <br />
        of <span class="text-primary"> Smart Logistics</span>
      </h1>
      <div class="row px-1 sm-center">
        <div class="col-12 col-md-6 mt-2">
          <a class="text-darkblue hover:text-primary" href="#tracking">
            <span>
              SCROLL
              <FeatherIcon icon="ArrowDownIcon" size="18" />
            </span>
          </a>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <p class="mb-3">
            GoLog Malaysia is a leading intelligent cold chain logistics
            platform, delivering seamless, precision-driven solutions for
            temperature-sensitive supply chains. As a cutting-edge platform, we
            leverage advanced technology to ensure peak freshness and quality
            from storage to final delivery, setting new standards in client
            service. Committed to pioneering innovation, GoLog Malaysia provides
            comprehensive, end-to-end logistics solutions that connect local and
            global markets, safeguarding the integrity of our clients' goods
            across every critical point in the cold chain.
          </p>
          <router-link class="sl-button mt-2" :to="{ name: 'About' }">
            Learn More
          </router-link>
        </div>
      </div>
    </div>

    <div id="tracking" class="fadeIn">
      <div class="overlay">
        <h1 class="title-md">
          <span class="text-primary">Hassle-free</span>
          <br class="sm-next-line" />
          tracking with
          <br class="sm-next-line" />
          GOLOG
        </h1>

        <TrackerModal />
      </div>
    </div>

    <div id="logistics-solution">
      <h1 class="title-md">
        Your <span class="text-primary">Ideal</span> Logistics
        <br class="sm-next-line" />
        Solution
      </h1>

      <b-row align-h="center" class="px-md-3">
        <div id="b1" class="box box-white mt-2">
          <div class="d-flex justify-content-center">
            <div class="div-img">
              <img
                src="@/assets/images/icons/main/cc-technology.svg"
                height="75"
              />
            </div>
          </div>
          <h3>Advanced Cold Chain Technology</h3>
          <p>
            Golog integrates state-of-the-art cold chain systems to ensure that
            temperature-sensitive products are transported under precise
            conditions, preserving quality and safety from start to finish.
          </p>
        </div>

        <div id="b2" class="box box-primary mt-2">
          <div class="d-flex justify-content-center">
            <div class="div-img">
              <img
                src="@/assets/images/icons/main/ete-transparency.svg"
                height="75"
              />
            </div>
          </div>
          <h3>End-to-End Transparency</h3>
          <p>
            Using real-time tracking and monitoring systems, Golog provides
            customers with full visibility over their shipments, giving peace of
            mind and ensuring accountability at every stage of the supply chain.
          </p>
        </div>

        <div id="b3" class="box box-white mt-2">
          <div class="d-flex justify-content-center">
            <div class="div-img">
              <img
                src="@/assets/images/icons/main/fcca.svg"
                height="68"
                class="mb-1"
              />
            </div>
          </div>
          <h3>Flexible, Client-Centric Approach</h3>
          <p>
            Golog tailors its services to meet the unique needs of each client,
            offering flexibility and adaptability to handle diverse logistics
            requirements across various sectors, from pharmaceuticals to food
            and beverages.
          </p>
        </div>

        <div id="b4" class="box box-white mt-2">
          <div class="d-flex justify-content-center">
            <div class="div-img">
              <img
                src="@/assets/images/icons/main/commitment.svg"
                height="75"
                class="mb-1"
              />
            </div>
          </div>
          <h3>Commitment to Sustainability</h3>
          <p>
            Golog integrates eco-friendly practices throughout its operations,
            aiming to reduce its environmental footprint with efficient route
            planning, reduced emissions, and sustainable packaging.
          </p>
        </div>
      </b-row>
    </div>

    <div id="testimony" class="fadeIn">
      <div
        class="d-md-flex justify-content-between align-items-center sm-center"
      >
        <div>
          <h1 class="title-md text-white">
            What our <span class="text-primary">clients</span> say about use
          </h1>
          <h4 class="w-md-75 w-lg-50 text-white">
            Don't just take our word for it - see what actual users of our
            service have to say about their experience.
          </h4>
        </div>
        <div
          v-if="screenWidth <= 1600"
          class="d-flex justify-content-center justify-content-md-end mt-2 mt-md-0"
        >
          <b-button variant="primary" class="btn-sm mr-1" @click="scrollLeft">
            <FeatherIcon icon="ArrowLeftIcon" size="18" />
          </b-button>
          <b-button variant="primary" class="btn-sm" @click="scrollRight">
            <FeatherIcon icon="ArrowRightIcon" size="18" />
          </b-button>
        </div>
      </div>
      <div ref="scrollContent" class="scrollable-container">
        <div class="flex-content">
          <div
            v-for="(t, index) in testimonies"
            :key="index"
            class="item text-wrap"
          >
            <p class="text-sm text-center">
              {{ t.content }}
            </p>
            <p class="text-primary mt-2">&#x2014; {{ t.from }}</p>
          </div>
        </div>
      </div>
    </div>

    <QuestionContent class="fadeIn" />
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import QuestionContent from "@/components/QuestionContent.vue";
import TrackerModal from "@/components/TrackerModal.vue";

export default {
  components: { QuestionContent, TrackerModal },
  mixins: [intersectionObserverMixin],
  data() {
    return {
      isMute: true,
      screenWidth: window.innerWidth,
      testimonies: [
        {
          content:
            "Since partnering with Golog, our cold chain logistics have become more efficient and reliable. Their real-time tracking system gives us peace of mind, and we’ve seen a significant reduction in delays and product spoilage. Highly recommended for anyone needing dependable cold chain solutions!",
          from: "Food Manufacturer",
        },
        {
          content:
            "Golog Malaysia has truly exceeded our expectations with their tailored logistics approach. They listened to our unique needs and designed a solution that perfectly fits our business. Their commitment to service quality and flexibility makes them stand out.",
          from: "Pharmaceutical Company",
        },
        {
          content:
            "We have never worked with a logistics provider as transparent and communicative as Golog. Their team keeps us updated at every stage, which has improved our supply chain visibility and allowed us to plan better. We couldn’t be happier with their service!",
          from: "E-commerce Retailer",
        },
        {
          content:
            "Maintaining product quality during transit has always been a challenge for us, but with Golog, we’ve found a solution we can trust. Their cold chain technology ensures that our products arrive in perfect condition, no matter the distance. It’s a game-changer for our business.",
          from: "Dairy Producer",
        },
      ],
    };
  },
  mounted() {
    this.setupIntersectionObserver(".box", 0.2);
    this.setupIntersectionObserver(".fadeIn", 0.2);
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
  methods: {
    scrollLeft() {
      const scrollContainer = this.$refs.scrollContent;
      scrollContainer.scrollBy({
        left: -350,
        behavior: "smooth",
      });
    },
    scrollRight() {
      const scrollContainer = this.$refs.scrollContent;
      scrollContainer.scrollBy({
        left: 350,
        behavior: "smooth",
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>
